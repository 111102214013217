import api from "./index";
import {get} from "lodash";

const BASE_URL = 'flats/'

export const list = (params) => api.get(BASE_URL, {params}).then(response => get(response, 'data'))
export const detail = (params) => api.get(`${BASE_URL}detail/`, {params}).then(response => get(response, 'data'))
export const filters = (params = null) => api.get(`${BASE_URL}filter/`, {params}).then(response => get(response, 'data'))

export const discount = () => api.get('common-discount/').then(response => get(response, 'data'))
